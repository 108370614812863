import { createContext, useContext, useState } from 'react'

const AuthContext = createContext({
    user: null,
    login: () => {},
    logout: () => {}
})

export const useAuth = () => {
    const [user, setUser] = useState({
        name: null,
        email: null,
        password: null
    })

    const addNameEmail = (data) => {
        setUser((prevState) => ({
            ...prevState,
            name: data.name,
            email: data.email
        }))
    }

    const addPassword = (data) => {
        setUser((prevState) => ({
            ...prevState,
            password: data.password
        }))
    }

    return { user, addNameEmail, addPassword }
}

export const AuthProvider = ({ children }) => {
    const auth = useAuth()
    return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
}

export const useAuthContext = () => {
    return useContext(AuthContext)
}
